<template>
  <transition name="slide">
    <CRow>
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <ModalPDF ref="visorPDF"></ModalPDF>
      <ModalVideo ref="modalVideo"></ModalVideo>
      <CCol col="8">
        <CCard class="border-warning">
          <CCardHeader>
            <strong>Nuevo Compromiso</strong>
            <div class="card-header-actions">
              <button @click="$refs.modalVideo.mostrarVideo('video-alumno-compromiso-entrega-doc.mp4')"
                      class="btn btn-outline-info" title="Ver video de ayuda">
                <font-awesome-icon icon="fas fa-question-circle" />
                Ayuda
              </button>
            </div>
          </CCardHeader>
          <CCardBody>
            <CForm id="frm-hojaruta">
              <template slot="header">
                Codigo de estudiante: {{ $route.params.id }}
              </template>
              <div class="table-responsive">
                <table class="table table-sm">
                  <tr>
                    <td>Carrera</td>
                    <td colspan="3"><strong>{{ carrera }}</strong></td>
                  </tr>
                  <tr>
                    <td>Nombres, apellidos</td>
                    <td colspan="3"><strong>{{ inscripcion.nombres }}, {{ inscripcion.apellido1 }} {{
                        inscripcion.apellido2
                      }}</strong></td>
                  </tr>
                  <tr>
                    <td>Tel&eacute;fono/Celular:</td>
                    <td><strong>{{ inscripcion.telefono }}</strong></td>
                    <td>C&oacute;digo del Alumno:</td>
                    <td><strong>{{ inscripcion.codigo_alumno }}</strong></td>
                  </tr>
                  <tr>
                    <td>Nacionalidad:</td>
                    <td><strong>{{ nacionalidad }}</strong></td>
                    <td>C.I./Pasaporte/DNI N°:</td>
                    <td><strong>{{ inscripcion.documento_identificacion }}</strong></td>
                  </tr>
                </table>
              </div>
              <CDataTable
                  :fields="fields"
                  :items="items"
                  :items-per-page="10"
                  hover
                  pagination
                  responsive
                  size="sm"
                  striped
              >
                <template #pendiente="{item}">
                  <td>
                    <input v-bind:id="'radio_n_'+item.id" :checked="item.entregado == 'N'"
                           class="form-control  form-control-sm"
                           type="radio" v-bind:name="'radio_'+item.id"
                           value="N"/>
                  </td>
                </template>
              </CDataTable>
              <div class="row">
                <div class="col">
                  <label class="form-label" for="fecha_presentacion">Fecha de presentaci&oacute;n</label>
                  <input id="fecha_presentacion" v-model="fecha_presentacion" class="form-control"
                         name="fecha_presentacion" type="date" value="">
                </div>
                <div class="col">
                  <label class="form-label" for="semestre">Semestre:</label>
                  <select id="semestre" class="form-control" name="semestre">
                    <option v-for='index in 10' :key='index' :selected="semestre==index" :value="index">
                      {{ index }}
                    </option>
                  </select>
                </div>
                <div class="col">
                  <CSelect
                      id="gestion" :options="gestiones"
                      :plain="true"
                      :value.sync="gestion"
                      label="Gestión:"
                      name="gestion"
                  >
                  </CSelect>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <label class="form-label" for="observacion">Observaciones</label>
                  <input id="observacion" class="form-control" maxlength="350"
                         name="observacion" type="text" value="">
                </div>
              </div>
              <hr/>
              <CButton class="mr-2" color="success" @click="update()">
                <span v-text="btnGrabar">Generar Compromiso</span>
              </CButton>
              <CButton class="mr-2" color="outline-danger" @click="reportePdf(compromiso_id)" v-if="compromiso_id>0">
                <font-awesome-icon icon="fa-regular fa-file-pdf" class="mr-2"/>
                Imprimir compromiso
              </CButton>
              <CButton color="primary" @click="goBack">Regresar a la pantalla anterior</CButton>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol col="4">
        <CCard>
          <CCardHeader>
            <strong>Compromisos anteriores</strong>
          </CCardHeader>
          <CCardBody class="p-1">
            <JqxGrid
                ref="gridSystem" :altrows="true"
                :columns="columns" :filterable="true"
                :height="'500px'" :localization="localization"
                :showfilterrow="true" :sortable="true"
                :source="dataAdapter" :theme="'bootstrap'"
                :width="'100%'" :columnsresize="true"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </transition>
</template>

<script>
import axios from 'axios'
import Table from "@/views/base/Table";
import JqxGrid from "jqwidgets-scripts/jqwidgets-vue/vue_jqxgrid";
import jqxInput from "jqwidgets-scripts/jqwidgets-vue/vue_jqxinput";
import Toast from "@/views/notifications/Toast";
import Alerts from "@/views/notifications/Alerts";
import ModalPDF from "@/views/notifications/ModalPDF";
import ModalVideo from "@/views/notifications/ModalVideo";

var sourceLst;
var address;
var modalPdf;

export default {
  name: 'Compromisos',
  components: {
    ModalVideo,
    ModalPDF,
    Alerts,
    Toast,
    JqxGrid,
    jqxInput, Table
  },
  props: {
    caption: {
      type: String,
      default: 'User id'
    },
  },
  data: () => {
    return {
      darkModal: false,
      items: [],
      fields: ['valor', 'pendiente'],
      inscripcion: {},
      carreras: [],
      carrera: '',
      nacionalidades: [],
      nacionalidad: '',
      gestiones: [],
      gestion: '',
      compromiso_id: 0,
      btnGrabar: 'Grabar Compromiso',
      semestre: '',
      fecha_presentacion: '',
      dataAdapter: new jqx.dataAdapter(sourceLst, {
        loadError: function (xhr, status, error) {
          console.log("Error ocurrido al obtener los datos del servidor, " + status + ", " + error);
          location.href = location.protocol + "//" + location.host + "/#/login";
        }
      }),
      columns: [
        {
          text: 'Fecha Registro', datafield: "fecha_compromiso", width: '40%',
          cellsformat: 'yyyy-MM-dd', filtertype: 'range',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Fecha de Registro</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Fecha"});
          }
        },
        {
          text: 'Semestre', datafield: "semestre", width: '20%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Semestre</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar por semeste"});
          }
        },
        {
          text: "Hoja de ruta", width: '40%', columntype: "button",
          cellclassname: "btn-red",
          renderer: function () {
            return '';
          },
          cellsrenderer: function () {
            return "Mostrar"
          },
          buttonclick: function (row, event) {
            let button = $(event.currentTarget);
            let grid = button.parents("[id^=jqxGrid]");

            let rowData = grid.jqxGrid('getrowdata', row);

            modalPdf.reportePdf(rowData.id);
          }
        },
      ],
      localization: {
        'emptydatastring': "No existen datos para esta SEDE",
      },
    }
  },
  beforeCreate: function () {
    const url = this.$apiAdress + '/api/inscripcion/' + this.$route.params.codigo_alumno + '/compromisoslista?token=' + localStorage.getItem("api_token");
    address = this.$apiAdress;
    sourceLst = {
      url: url,
      datafields: [
        {name: 'id', type: "int"},
        {name: 'fecha_compromiso', type: "date", format: 'yyyy-MM-dd H:mm:ss'},
        {name: 'semestre', type: "int"},
      ],
      data: {
        sede: this.sede
      },
      datatype: "json",
      root: "Rows",
      id: 'id',
      sortcolumn: 'fecha_compromiso',
      sortdirection: 'desc',
      cache: false,
      filter: () => {
        this.$refs.gridSystem.updatebounddata("filter");
      },
      sort: () => {
        this.$refs.gridSystem.updatebounddata("sort");
      },
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    reportePdf(compromiso_id) {
      modalPdf.$refs.alert.show("Descargando documento");
      axios.get(
          modalPdf.$apiAdress + '/api/inscripcion/' + compromiso_id + '/compromisopdf?token=' + localStorage.getItem("api_token"),
          {responseType: 'blob'}
      ).then((response) => {
        modalPdf.$refs.alert.hide();
        modalPdf.$refs.visorPDF.mostrarDocumento(response.data);
      }).catch(function (error) {
        modalPdf.$refs.alert.hide();
        modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
      });
    },
    update() {
      modalPdf.$refs.alert.show("Grabando datos");
      let myForm = document.getElementById('frm-hojaruta');
      let form_data = new FormData(myForm);
      form_data.append('_method', 'POST');
      axios.post(this.$apiAdress + '/api/inscripcion/' + modalPdf.$route.params.id + '/' + modalPdf.$route.params.codigo_alumno + '/compromisograbar?token=' + localStorage.getItem("api_token"),
          form_data)
          .then(function (response) {
            modalPdf.compromiso_id = response.data.id;
            modalPdf.$refs.gridSystem.updatebounddata();
            modalPdf.reportePdf(response.data.id);
            modalPdf.$refs.mensajeToast.makeToast('Info', 'Compromiso registrado/actualizado.', 'success');
            modalPdf.$refs.alert.hide();
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
  },
  mounted: function () {
    modalPdf = this;
    axios.get(modalPdf.$apiAdress + '/api/inscripcion/0/' + modalPdf.$route.params.codigo_alumno + '/edit?token=' + localStorage.getItem("api_token"))
        .then(function (response) {
          modalPdf.inscripcion = response.data.inscripcion;

          axios.get(modalPdf.$apiAdress + '/api/inscripcion/' + localStorage.sedeSeleccionada + '/create?token=' + localStorage.getItem("api_token"))
              .then(function (response) {
                let valorPorDefecto = [{value: '', label: ':: SELECCIONAR ::'}];
                modalPdf.carreras = response.data.carreras;
                modalPdf.nacionalidades = valorPorDefecto.concat(response.data.nacionalidades);
                for (let fac in modalPdf.carreras) {
                  for (let carr in modalPdf.carreras[fac]) {
                    if (modalPdf.carreras[fac][carr].value == modalPdf.inscripcion.carrera_id) {
                      modalPdf.carrera = modalPdf.carreras[fac][carr].label;
                    }
                  }
                }
                for (let fac in modalPdf.nacionalidades) {
                  if (modalPdf.nacionalidades[fac].value == modalPdf.inscripcion.nacionalidad_id) {
                    modalPdf.nacionalidad = modalPdf.nacionalidades[fac].label;
                  }
                }
              })
              .catch(function (error) {
                modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
              });
        })
        .catch(function (error) {
          modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
        });
    axios.get(modalPdf.$apiAdress + '/api/inscripcion/' + modalPdf.$route.params.id + '/' + modalPdf.$route.params.codigo_alumno + '/compromisos?token=' + localStorage.getItem("api_token"))
        .then(function (response) {
          modalPdf.items = response.data.documentos;
          modalPdf.fecha_presentacion = response.data.fecha_presentacion;
          modalPdf.gestiones = response.data.gestiones;
          modalPdf.gestion = response.data.mesActual;
          modalPdf.semestre = response.data.semestre;
          modalPdf.compromiso_id = response.data.compromiso_id;
          if (modalPdf.compromiso_id > 0) {
            modalPdf.btnGrabar = "Modificar datos del compromiso";
          } else {
            modalPdf.btnGrabar = "Grabar Compromiso";
          }
        })
        .catch(function (error) {
          modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
        });
  }
}
</script>